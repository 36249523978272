<template>
  <div class="relative">
    <Button
      icon="pi pi-chevron-left"
      label="voltar"
      class="p-button-secondary mt-4"
      @click="$router.push('/')"
    />

    <Panel class="my-4">
      <template #header>
        <div class="font-medium">
          <i class="pi pi-shopping-cart text-xl"></i> &nbsp;Carrinho
        </div>
      </template>

      <div class="carrinho-mensagem" v-if="carrinhoGrade.length == 0">
        <p>Seu carrinho está vazio!</p>

        <Button label="Vamos adicionar algo?" @click="$router.push('/')" />
      </div>
      <div class="carrinho-itens" v-else>
        <div
          class="carrinho-item px-2"
          v-for="produto in carrinhoGrade"
          :key="produto.id"
        >
          <div class="grid carrinho-item-header font-bold text-center">
            <div class="col-2 text-left">
              <div class="carrinho-item-cor-imagem">
                <img :src="produto.foto" alt="" class="border-circle" />
              </div>
            </div>
            <div class="col-8">
              <span class="carrinho-item-nome block pt-4 ">{{
                produto.nome
              }}</span>
            </div>

            <div class="col-2 text-right">
              <Button
                icon="pi pi-trash"
                v-tooltip.top="'Excluir Produto'"
                class="p-button-rounded p-button-danger mt-3"
                @click="excluirItem($event, produto.id)"
              />
            </div>
            <div class="col-6">
              <div class="carrinho-item-valor text-left">
                {{ $utils.formatCurrency(produto.valor) }}
              </div>
            </div>
            <div class="col-6">
              <div class="carrinho-item-quantidade text-right">
                Qnt: {{ produto.qnt }}
              </div>
            </div>
          </div>

          <div
            class="grid text-center carrinho-item-cor my-2 pb-2"
            v-for="per in produto.personalizacoes"
            :key="per.id"
          >
            <div
              class="px-3 carrinho-item-tamanho"
              v-for="i in per.itens"
              :key="i.id"
            >
              <div v-if="i.qntSelecionado > 0">
                <span class="carrinho-item-tamanho-nome block line-height-4">{{
                  i.nome + " (" + i.qntSelecionado + ")"
                }}</span>
                <span class="carrinho-item-tamanho-quantidade py-1 px-2">{{
                  $utils.formatCurrency(i.valor * i.qntSelecionado)
                }}</span>
              </div>
            </div>
          </div>
          <span
            class="carrinho-item-observacao text-left"
            v-if="produto.observacao"
            >Observação: <span>{{ produto.observacao }}</span></span
          >
        </div>
      </div>
    </Panel>

    <Panel class="my-3" v-if="totalizadores != null" :toggleable="true">
      <template #header>
        <div class="font-medium">
          <i class="pi pi-map-marker text-xl"></i> &nbsp;Alterar Endereço de Entrega ou CPF?
        </div>
      </template>

      <EnderecoCliente @atualizado="cadastroAtualizado" @autenticar="displayCadastro = true" @loading="loadingEnderecoCliente" />
    </Panel>

    <Panel class="my-3" v-if="totalizadores != null">
      <template #header>
        <div class="font-medium">
          <i class="pi pi-wallet text-xl"></i> &nbsp;Pagamento
        </div>
      </template>

      <div class="grid carrinho-total">

        <div class="col-12 md:col-4">
          <div class="carrinho-total-produtos mb-2">
            <span class="font-medium">Produtos:</span> {{ $utils.formatCurrency(totalizadores.valorVenda) }}
          </div>
          <div class="carrinho-total-produtos mb-2">
            <span class="font-medium">Entrega:</span> {{ $utils.formatCurrency(totalizadores.valorFrete) }}
          </div>
          <div class="carrinho-total-produtos mb-2" v-show="totalizadores.valorDesconto > 0">
            <span class="font-medium">Desconto:</span> {{ $utils.formatCurrency(totalizadores.valorDesconto) }}
          </div>
          <div class="carrinho-total-produtos mb-3">
            <span class="font-medium">Total: {{ $utils.formatCurrency(totalizadores.valorTotal) }}</span>
          </div>

          <div class="carrinho-frete-resultados" v-if="fretes.length > 0 && cobrarFrete">
            <div class="font-medium">Tipos de Entrega:</div>
            <div
              v-for="f of fretes"
              :key="f.id"
              class="carrinho-frete-resultado"
            >
              <div>
                <RadioButton
                  :id="'frete-' + f.id"
                  name="frete"
                  :value="f"
                  v-model="frete"
                  style="vertical-align: top"
                />
                <label class="inline-block" :for="'frete-' + f.id">
                  {{ f.nome }} - {{ $utils.formatCurrency(f.valor) }}
                  <span class="text-xs block" v-if="(f.id.indexOf('-') != -1) && f.id.split('-')[0] == 'retirar'">{{ this.$root.empresa.distribuidores[f.id.split("-")[1]].nome }}<br>{{ this.$root.empresa.distribuidores[f.id.split("-")[1]].endereco }}</span>
                  <span class="text-xs block">{{ f.tempo }}</span>
                  <span class="text-xs block underline" v-if="horarioFuncionamento != ''">Horário de Funcionamento: {{ horarioFuncionamento }}</span>
                </label>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 md:col-8 text-center" v-if="displayPagamento == false">
          <Button
            class="p-button-success mt-4"
            label="Escolher Forma de Pagamento"
            icon="pi pi-check"
            iconPos="right"
            @click="realizarPagamento"
          />
        </div>

        <div class="col-12 md:col-8" v-else-if="pago">

          <Card class="bg-green-100 card-pago">
            <template #content>
              <div class="p-3 text-center font-bold">
                <i class="pi pi-check"></i> PAGAMENTO REGISTRADO
              </div>
            </template>
          </Card>

        </div>

        <div class="col-12 md:col-8" v-else>

          <Card class="bg-gray-100 card-pix" v-if="metodo_pagamento == 'pix'">
            <template #content>
              <div class="grid">
                <div class="col-12 md:col-6 text-center">
                  <div class="font-bold mb-2">QR Code:</div>
                  <img :src="'data:image/jpeg;base64,' + pix_qr" alt="" title="QR Code" class="w-full h-auto" />
                </div>
                <div class="col-12 md:col-6 text-center">
                  <div class="font-bold mb-2">Copie e Cole:</div>
                  <Textarea v-model="pix_copia_cola" readonly="true" rows="8" style="width: 100%;" onclick="this.focus();this.select()" />

                  <div>
                    <ProgressSpinner style="width:40px; vertical-align: middle" strokeWidth="4" /> 
                    <span class="ml-2 font-medium text-pink-700">Aguardando Pagamento</span>
                  </div>

                </div>
              </div>
            </template>
          </Card>

          <Card class="bg-gray-100 card-cartao" v-else-if="metodo_pagamento == 'cartao'">
            <template #content>
              <div class="grid">
                <div class="col-10">
                  <InputMask
                    id="cartao_numero"
                    type="text"
                    v-model="cartao_numero"
                    mask="9999 9999 9999 9999"
                    placeholder="número do cartão"
                    :class="(fieldsError.some(v => v === 'cartao_numero'))?'w-full p-invalid':'w-full'"
                    @blur="identificaMetodo"
                  />
                </div>
                <div class="col-2">
                  <div class="pt-2" v-if="cartao_metodo != null">
                    <img :src="cartao_metodo.secure_thumbnail" :alt="cartao_metodo.name" :title="cartao_metodo.name" />
                  </div>
                </div>
                <div class="col-7">
                  <InputText
                    id="cartao_nome"
                    type="text"
                    v-model="cartao_nome"
                    placeholder="nome do titular"
                    :class="(fieldsError.some(v => v === 'cartao_nome'))?'w-full p-invalid':'w-full'"
                  />
                </div>
                <div class="col-3">
                  <InputMask
                    id="cartao_validade"
                    v-model="cartao_validade"
                    mask="99/9999"
                    slotChar="mm/aaaa"
                    placeholder="validade"
                    :class="(fieldsError.some(v => v === 'cartao_validade'))?'w-full p-invalid':'w-full'"
                  />
                </div>
                <div class="col-2">
                  <InputNumber
                    id="cartao_cvv"
                    v-model="cartao_cvv" 
                    :max="999"
                    :allowEmpty="true"
                    placeholder="cvv"
                    :class="(fieldsError.some(v => v === 'cartao_cvv'))?'p-invalid':''"
                    inputClass="w-full"
                  />
                </div>
                <div class="col-7 pb-0">

                  <div v-if="parcelas.length > 0">
                    <Dropdown
                      v-model="parcela"
                      :options="parcelas" 
                      optionLabel="recommended_message" 
                      :class="(fieldsError.some(v => v === 'parcela'))?'w-full p-invalid':'w-full'"
                    />
                  </div>

                </div>
                <div class="col-5 pb-0 text-right">

                  <Button
                    icon="pi pi-check"
                    label="Pagar"
                    class="p-button-success"
                    @click="pagar"
                  />

                </div>
              </div>
              
            </template>
          </Card>

          <Card class="bg-gray-100 card-escolha" v-else>
            <template #content>
              <div class="grid">
                <div class="col-12 text-center font-medium mb-2" v-if="typeof $root.empresa.mp_public_key != 'undefined'">
                  Formas de pagamento <u>ONLINE</u>:
                </div>
                <div class="col-12 text-center" v-if="typeof $root.empresa.mp_public_key != 'undefined'">
                  <Button
                    icon="pi pi-qrcode"
                    label="PIX"
                    class="p-button-secondary p-button-lg"
                    @click="pagar"
                  />
                  <Button
                    icon="pi pi-credit-card"
                    label="CARTÃO"
                    class="p-button-secondary p-button-lg ml-4"
                    @click="pagarCartao"
                  />
                </div>
                <div class="col-12 text-center font-medium mb-2 mt-3" v-if="presencialDinheiro || presencialCartao">
                  Formas de pagamento <u>PRESENCIAL</u>:
                </div>
                <div :class="(presencialDinheiro)?'col-6 text-center':'col-12 text-center'" v-if="presencialCartao">
                  <Button
                    icon="pi pi-credit-card"
                    label="CARTÃO"
                    class="p-button-secondary p-button-lg"
                    @click="pagarPresencial('cartao')"
                  />
                </div>

                <div :class="(presencialCartao)?'col-6 text-center':'col-12 text-center'" v-if="presencialDinheiro">
                  <Button
                    icon="pi pi-money-bill"
                    label="Dinheiro"
                    class="p-button-secondary p-button-lg"
                    @click="pagarPresencial('dinheiro')"
                  />
                </div>

              </div>
            </template>
          </Card>

        </div>

      </div>
    </Panel>

    <div class="loading" v-show="loading">
      <div class="center-spinner">
        <ProgressSpinner />
      </div>
    </div>

    <Dialog
      :position="$utils.isMobileCheck() ? 'bottom' : 'center'"
      v-model:visible="displayCadastro"
      style="width: 530px; max-width: 98%"
      :modal="true"
      :closable="true"
    >
      <template #header>
        <h5 class="m-0 text-xl font-medium">
          <i class="pi pi-user" style="font-size: 1.3rem"></i> Para realizar o
          pagamento, identifique-se
        </h5>
      </template>
      <CadastroCliente @logado="logado" />
    </Dialog>

  </div>
</template>

<script>
import CadastroCliente from "../componentes/CadastroCliente.vue";
import EnderecoCliente from "../componentes/EnderecoCliente.vue";
import { getDatabase, ref, onValue} from "firebase/database";

export default {
  data() {
    return {
      fieldsError: [],
      loading: true,
      displayCadastro: false,
      displayPagamento: false,

      carrinho: null,
      carrinhoGrade: [],
      totalizadores: null,

      cobrarFrete: true,
      frete: null,
      fretes: [],
      horarioFuncionamento: "",

      /// PGTO ///

      parcelas: [],

      pago: false,
      metodo_pagamento: "",

      cartao_numero: "",
      cartao_nome: "",
      cartao_validade: "",
      cartao_cvv: null,
      cartao_metodo: null,
      parcela: null,

      pix_qr: "",
      pix_copia_cola: "",
      
      presencialDinheiro: false,
      presencialCartao: false,

    };
  },
  watch: {
    
    "$root.carrinho": {
      handler: function (carrinho) {

        this.metodo_pagamento = '';

        if (typeof carrinho != "undefined" && carrinho != null) {

          if(typeof carrinho.pagamentoEfetivado == "boolean")
            this.pago = carrinho.pagamentoEfetivado;

          this.carrinho = this.$utils.getStdObject(carrinho);

          this.carrinhoGrade = this.carrinho.listaProdutos;

          this.totalizadores = {
            valorDesconto: this.carrinho.valorDesconto,
            valorFrete: (typeof this.carrinho.valorEntrega == "number"?this.carrinho.valorEntrega:0),
            valorVenda: this.carrinho.valorVenda,
            valorTotal: this.carrinho.valorTotal,
            itens: this.carrinho.qnt,
          };

          if(typeof carrinho.tipoEntrega != "undefined") {
            // Já Existe Um tipo de Entrega Selecionado

            if(this.fretes.length > 0) {
              // Nos fretes já carregados, seleciona a entrega do carrinho

              const idx = this.fretes.findIndex((e) => e.id == carrinho.tipoEntrega.id);
              if(idx != -1)
                this.frete = this.fretes[idx];
              else
                this.frete = null; // Caso não exista a entrega do carrinho na lista

            } else {
              // Como não carregou ainda os fretes, cria a lista temporária e seleciona a entrega do carrinho criada

              this.fretes = [
                {
                  id: carrinho.tipoEntrega.id,
                  nome: carrinho.tipoEntrega.nome,
                  tempo: "",
                  valor: carrinho.tipoEntrega.valor,
                  default: false,
                }
              ];

              this.frete = this.fretes[0];

            }

          } else {
            // Não existe entrega selecionada no carrinho

            if(this.fretes.length > 0) {

              // Nos fretes já carregados seleciona o default

              const idx = this.fretes.findIndex((e) => typeof e.default == "boolean" && e.default == true);
              if(idx != -1)
                this.frete = this.fretes[idx];
              else
                this.frete = null; // Caso não exista default

            } else {

              // Como não existe lista carregada ainda para selecionar o default

              this.frete = null;

            }

          }

        } else {
          // Limpa Tela
          this.fieldsError = [];
          this.displayCadastro = false;
          this.carrinhoGrade = [];
          this.totalizadores = null;
          this.carrinho = null;
          this.frete = null;
          this.pago = false;
        }

        this.loading = false;
      },
      deep: true,
      immediate: true,
    },

    "$root.user": {
      handler: async function (user) {
        if (typeof user != "undefined" && user != null) {

          const response = await this.$api.get("/frete/" + this.$root.empresa.id);

          if (!response.data.success) {
            console.log(response.data.error);
            this.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });
          } else {
            this.fretes = response.data.data;
          }

          this.cartao_nome = user.nome;

        }
      },
      deep: true,
      immediate: true,
    },

    "$root.empresa": {
      handler: async function (empresa) {
        this.atualizaCobrarFrete();

        if(empresa != null) {
          if(empresa.funcionamentoInicio == "00:00" && empresa.funcionamentoFim == "23:59")
            this.horarioFuncionamento = "24 horas";
          else
            this.horarioFuncionamento = "de " + empresa.funcionamentoInicio + " às " + empresa.funcionamentoFim;

          if(empresa.id == "LNOH5SeajuesNL4Iyn8zHLbTQap2" || empresa.id == "5CI3kFoYYgbNmxQZLmhsGPvC32f2") {
            this.presencialCartao = true;
            this.presencialDinheiro = true;
          } else {
            this.presencialDinheiro = false;
            this.presencialCartao = false;
          }

        } else {
          this.horarioFuncionamento = "";
        }

      },
      deep: true,
      immediate: true,
    },

    totalizadores: {
      handler: async function (totalizadores) {
        if (totalizadores != null) {
          this.atualizaCobrarFrete();

          if(this.cobrarFrete)
            this.totalizadores.valorTotal = totalizadores.valorVenda + totalizadores.valorFrete - totalizadores.valorDesconto;
          else {
            this.totalizadores.valorTotal = totalizadores.valorVenda - totalizadores.valorDesconto;
            this.totalizadores.valorFrete = 0;
          }
        }
      },
      deep: true,
      immediate: true,
    },

    metodo_pagamento: function(val) {
      const self = this;
      if(val == "pix") {

        const refPagamento = ref(getDatabase(), '/VipDelivery/CarrinhoWeb/' + this.$root.empresa.id + '/' + this.$root.user.id + '/pagamentoEfetivado');
        onValue(refPagamento, (snapshot) => {

          if(snapshot.exists() && snapshot.val()) {

            self.pago = true;
            self.metodo_pagamento = '';

            self.$root.carregaCarrinho();
            self.$root.carregaPedidos();
            self.$router.push('/listapedidos');

          }

        });

      }
    },

    fretes: function(fretes, oldFretes) {

      if(oldFretes.length > 0) {

        if(this.frete != null) {
          const frete = this.frete;
          const idx = fretes.findIndex((e) => e.id == frete.id);
          if(idx != -1)
            this.frete = this.fretes[idx];
          else
            this.frete = null;

        }

      }

      if(this.frete == null) {

        const idx = fretes.findIndex((e) => typeof e.default == "boolean" && e.default == true);
        if(idx != -1)
          this.frete = this.fretes[idx];

      }

      /***************/

      if(!this.cobrarFrete) {

        const idx = fretes.findIndex((e) => e.id.indexOf("vipapp") != -1);
        if(idx != -1)
          this.frete = this.fretes[idx];

      }

      /***************/

    },

    frete: async function(val) {

      if(this.carrinho != null) {
        
        this.loading = true;

        const response = await this.$api.post('/carrinho/frete', {
          "idEmpresa": this.$root.empresa.id,
          "frete": val
        });

        if(!response.data.success) {
          this.frete = null;
          this.totalizadores.valorFrete = 0;
          console.log(response.data);
        } else {
          this.totalizadores.valorFrete = val.valor;
        }

        this.loading = false;

      }

    }

  },

  methods: {

    loadingEnderecoCliente(data) {
      this.loading = data;
    },

    atualizaCobrarFrete() {
      let cobrarFrete = true;
      
      if(typeof this.$root.empresa != "undefined" && this.$root.empresa != null && this.totalizadores != null && typeof this.totalizadores.valorVenda == "number") {

        if(typeof this.$root.empresa.cobrarFrete == "boolean" && !this.$root.empresa.cobrarFrete) {
          if(typeof this.$root.empresa.valorMinimoFrete == "undefined" || this.$root.empresa.valorMinimoFrete == 0) {
            cobrarFrete = false;
          } else {
            if(this.totalizadores.valorVenda >= this.$root.empresa.valorMinimoFrete)
              cobrarFrete = false;
          }
        }
      
      }

      this.cobrarFrete = cobrarFrete;
    },

    realizarPagamento() {

      if (this.$root.user == null) {
        this.displayCadastro = true;
        return
      }
      
      if(this.frete == null) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Primeiro é necessário selecionar o tipo de entrega!",
          life: 5000,
        });
        return
      }

      // if(typeof this.$root.empresa.mp_public_key == "undefined") {
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "ERRO!",
      //     detail: "Loja não preparada para receber pagamentos!",
      //     life: 5000,
      //   });
      //   return
      // }

      this.displayPagamento = true;

    },

    logado() {
      this.displayCadastro = false;
    },

    cadastroAtualizado() {

      this.$root.carregaUsuario();

    },

    excluirItem(event, id) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.loading = true;
          self.$api
            .post("/carrinho/excluir", {
              idP: id,
              idEmpresa: self.$root.empresa.id 
            })
            .then((res) => {
              res = res.data;

              if (res.success) {
                self.$root.carregaCarrinho();
              }

              self.$toast.add({
                severity: res.success ? "success" : "error",
                summary: res.success ? "Sucesso!" : "ERRO!",
                detail: res.message,
                life: res.success ? 3000 : 5000,
              });
            });
        },
      });
    },

    pagarCartao() {
      // Pagamento por cartão, online.
      if(typeof this.$root.user.cpf == "undefined" || this.$root.user.cpf == "") {

        this.$toast.add({
          severity: "error",
          summary: "ATENÇÃO!",
          detail: "Para pagamento Online é necessário que seu cadastro possua o número de seu CPF! Atualize e tente novamente.",
          life: 8000,
        });
        return;

      }

      this.metodo_pagamento = 'cartao';

    },

    //OK
    async pagar() {
      if(this.metodo_pagamento == "cartao")
        this.processaPagamento();
      else
        this.pagarPix();
    },

    // OK
    async pagarPix() {

      if(typeof this.$root.user.cpf == "undefined" || this.$root.user.cpf == "") {

        this.$toast.add({
          severity: "error",
          summary: "ATENÇÃO!",
          detail: "Para pagamento Online é necessário que seu cadastro possua o número de seu CPF! Atualize e tente novamente.",
          life: 8000,
        });
        return;

      }

      if(typeof this.carrinho.MP != "undefined") {

        this.pix_qr = this.carrinho.MP.qr_code_base64;
        this.pix_copia_cola = this.carrinho.MP.qr_code;

        this.metodo_pagamento = "pix";

        return;

      }

      if(typeof this.$root.empresa.mp_public_key == "undefined") {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Empresa não preparada para receber pagamentos (MP)!",
          life: 5000,
        });
        return;
      }

      this.loading = true;

      const response = await this.$api.post('/mercadopago/web/pix', {
        "idEmpresa": this.$root.empresa.id,
      });

      if(!response.data.success) {
        console.log(response.data);
      } else {
        this.pix_qr = response.data.data.qr_code_base64;
        this.pix_copia_cola = response.data.data.qr_code;

        this.metodo_pagamento = "pix";

      }

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

      this.loading = false;

    },

    // TODO REVISAR
    async processaPagamento() {

      if(this.cartao_numero == "" || this.cartao_nome == "" || this.cartao_validade == "" || this.cartao_cvv == "" || this.cartao_metodo == null || this.parcela == null) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Informe todas as informações do cartão!",
          life: 5000,
        });
        return;
      }

      if(typeof this.$root.empresa.mp_public_key == "undefined") {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Empresa não preparada para receber pagamentos (MP)!",
          life: 5000,
        });
        return;
      }

      this.loading = true;

      const mp = new MercadoPago(this.$root.empresa.mp_public_key);

      let response;

      try {

        response = await mp.createCardToken({
          cardNumber: this.cartao_numero.replace(/[^0-9]/g, ""),
          cardholderName: this.cartao_nome,
          cardExpirationMonth: this.cartao_validade.split('/')[0],
          cardExpirationYear: this.cartao_validade.split('/')[1],
          securityCode: this.cartao_cvv.toString(),
          identificationType: 'CPF',
          identificationNumber: this.$root.user.cpf.replace(/[^0-9]/g, ""),
        });

      } catch (error) {

        console.log(error);

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Erro nas informações do cartão!",
          life: 5000,
        });

        this.loading = false;

        return;

      }

      response = await this.$api.post('/mercadopago/web/cartao', {
        "idEmpresa": this.$root.empresa.id,
        "cartao_token": response.id,
        "metodo_pagamento": this.cartao_metodo.id,
        "parcelas": this.parcela.installments
      });

      if(response.data.success) {
        this.metodo_pagamento = '';
        this.pago = true;

        this.$root.carregaCarrinho();
        this.$root.carregaPedidos();
        this.$router.push('/listapedidos');

      } else {
        console.log(response);
      }

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

      this.loading = false;

    },

    async identificaMetodo() {

      const cartao = this.cartao_numero.replace(/[^0-9]/g, "").substr(0,6);

      if(typeof this.$root.empresa.mp_public_key != "undefined" && cartao.length >= 6) {

        const mp = new MercadoPago(this.$root.empresa.mp_public_key);

        try {

          let response = await mp.getPaymentMethods({ bin: cartao });

          if(response.results.length > 0) {
            this.cartao_metodo = response.results[0];
          }

          response = await mp.getInstallments({
            amount: this.totalizadores.valorTotal.toFixed(2),
            locale: 'pt-BR',
            bin: cartao,
          });

          if(response.length > 0) {
            this.parcelas = response[0].payer_costs;
            this.parcela = this.parcelas[0];
          }

        } catch (error) {

          console.log(error);

        }

      }

    },

    async pagarPresencial(forma) {

      this.loading = true;

      const response = await this.$api.post('/carrinho/pagamento-presencial', {
        "idEmpresa": this.$root.empresa.id,
        "formaPagamento": forma
      });

      if(response.data.success) {
        this.metodo_pagamento = '';
        this.pago = true;

        this.$root.carregaCarrinho();
        this.$root.carregaPedidos();
        this.$router.push('/listapedidos');

      } else {
        console.log(response);
      }

      this.loading = false;

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

    },

  },
  components: {
    CadastroCliente,
    EnderecoCliente
  },
};
</script>

<style lang="scss" scoped>
.carrinho-itens .carrinho-item:nth-child(even) {
  background: $secondaryBackgroundColor;
}

.carrinho-item-cor {
  border-bottom: 1px solid #ccc;
}

.carrinho-itens .carrinho-item:nth-child(even) .carrinho-item-cor {
  border-color: white;
}

.carrinho-item .carrinho-item-cor:last-child,
.carrinho-itens .carrinho-item:nth-child(even) .carrinho-item-cor:last-child {
  border-bottom: none;
}

.carrinho-item-cor-imagem {
  width: 105px;
  padding: 0.5rem;
}

.carrinho-item-cor-imagem img {
  width: 50px;
  height: 50px;
}

.carrinho-item-tamanho-quantidade {
  border-top: 1px solid;
}

.button-carrinho-frete {
  padding: 0 0.5rem;
  vertical-align: baseline;
}

.carrinho-frete-resultado {
  margin-top: 0.5rem;
}

.carrinho-frete-resultado label {
  margin-left: 0.5rem;
  max-width: 90%;
}

:deep(.card-cartao .p-card-content), :deep(.card-pix .p-card-content) {
  padding: 0;
}

</style>